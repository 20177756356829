import React from "react";

//Customizable Area Start

import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
export const login = require("../assets/login.jpg");
export const inspidate = require("../assets/inspidate.png");


const theme = createTheme({
  palette: {
    primary: {
      main: '#00aeef'
    },
    secondary: {
      main: '#4D4D4D'
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});

const styles = {
  button: {
    borderRadius: 10,
    padding: '15px 60px',
  },
  inputs: {
    borderRadius: 10,
  },
};
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    return (
       //Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container component="main" style={{ height: '100vh', padding: '20px', overflowX: 'hidden', display: 'flex', alignItems: 'start',}} className={''}>
          <Grid item xs={false} sm={4} md={7} className={''}>
            <Box>
                <img src={login} alt="" style={{objectFit: 'contain', width: '100%',borderRadius: '40px'}} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '96%', padding: '30px'}}>
              <Box mb={3} >
                <img src={inspidate} alt="" style={{height: '70px'}} />
              </Box>
              <Typography component="h1" variant="h4" style={{fontWeight: 600, display: 'flex', alignItems: 'center'}}>
              <ArrowBackIcon style={{fontWeight: 600, fontSize: '2.125rem'}} /> Forgot Password
              </Typography>
              <Typography component="h3" variant="h6" style={{fontWeight: 400, marginTop: '10px'}}>
                Enter your registered email address.
              </Typography>
                <Box>
                  <Box my={3}>
                    <Typography variant="body1" style={{fontWeight:600}} component="h6">
                      Email ID
                    </Typography>
                    <TextField fullWidth  
                    className='inputRadius' type="email" value={ this.state.emailValue } variant="outlined" placeholder="Email ID" onChange={(e) => this.handleEmailChange(e.target.value)}  />
                  </Box>
                <Box>
                  <Button variant="contained" color="primary" type="submit" fullWidth size="large"  onClick={() => this.goToOtpAfterEmailValidation({ accountType: "email_account", email: this.state.emailValue })} style={{...styles.button, color:'white'}} disableElevation>
                    Submit
                  </Button>
                </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
   //Customizable Area Start
    );
  }
}
