import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {Grid, Box, Link} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { inspidate } from '../../blocks/landingpage/src/assets';

const useStyles = makeStyles({
  root: {
    minWidth: 0,
    borderRadius: 20,
    height: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  link: {
    margin: '0 10px',
    color: 'black'
  },
  icon:{
    height: 80,
    width: 80,
    objectFit: 'contain',
    objectPosition: "center",
  }
});

export default function Footer(props:any) {
  const classes = useStyles();
  return (
    <Grid container alignItems='center'>
        <Grid item md={6}>
            <img src={inspidate} style={{ height: '60px' }} alt="Inspidte" />
        </Grid>
        <Grid item md={6}>
            <Box className={classes.links}>
                <Link className={classes.link}>Home</Link>
                <Link className={classes.link}>About Us</Link>
                <Link className={classes.link}>Privacy Policy</Link>
                <Link className={classes.link}>Terms and Conditions</Link>
            </Box>
        </Grid>
    </Grid>
  );
}
