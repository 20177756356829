import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {Button, Box} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    minWidth: 0,
    borderRadius: 20,
    height: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
   },
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 600,
    color: '#212121',
    marginTop: 10,
  },
  icon:{
    height: 80,
    width: 80,
    objectFit: 'contain',
    objectPosition: "center",
  }
});

export default function MarketPlaceCard(props:any) {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent style={{display: 'flex', alignItems: 'center', justifyItems: 'space-between', flexDirection: 'column'}}>
        <Box style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
          <img src={props.icon} className={classes.icon} alt={props.title} />
        </Box>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.title}
        </Typography>
      </CardContent>
    </Card>
  );
}
