import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Box, Paper,  Tabs, Tab, Link, Button, IconButton, InputBase} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FlagIcon from '@material-ui/icons/Flag';
import ShareIcon from '@material-ui/icons/Share';
import FacebookIcon from '@material-ui/icons/Facebook';
import { inspidate, bag, mentor1, mentor2, mentor3, calendar, video, v1, v2, v4, v3 } from '../../blocks/landingpage/src/assets';
import "./SearchBox.css"

const useStyles = makeStyles({
  root: {
    background: "white",
    margin: "25px 0", 
    borderRadius: 10,
    padding: "35px", 
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
  }, 
  card: {
    background: "white",
    margin: "25px 0", 
    borderRadius: 10,
    padding: "25px", 
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
  },
  displayFlex:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tab:{
    minWidth: "auto",
    fontSize: "16px"
  },
  videoTab:{
    minWidth: "auto",
    padding: "6px 40px",
    fontSize: "16px",
    borderRadius: "30px",
  },
  tabs: {
    borderBottom: "1px solid rgb(77 77 77 / 17%)" 
  },
  videoTabs: {
    borderRadius: "30px",
    marginBottom: "20px",
    backgroundColor: "#e0e0e0",
    "& .Mui-selected": {
      backgroundColor: "#00aeef",
      color:"white",  
    },
    "& > span": {
      display: "none"
    }
  },
  displayFlexCenter:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MentorVideoCard(props:any) {
  const classes = useStyles();
  const [tabs, setTabs] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newTabs: number) => {
    setTabs(newTabs);
  };

  return (
    <Box className={classes.card}>
      <Box>
          <Box className={classes.displayFlex}>
            <Typography variant="h5" style={{fontWeight: 600, margin:"15px 0"}} component="h3">
              Videos
            </Typography>
            <Tabs indicatorColor="primary" className={classes.videoTabs} textColor="primary" value={tabs} onChange={handleChange} aria-label="Video Tabs">
              <Tab className={classes.videoTab} label="Latest Videos" {...a11yProps(0)} />
              <Tab className={classes.videoTab} label="Recorded Videos" {...a11yProps(1)} />
            </Tabs>
            </Box>
              <Box   role="tabpanel"
                  hidden={tabs !== 0}
                  id={`simple-tabpanel-${0}`}
                  aria-labelledby={`simple-tab-${0}`}
                    >
                {tabs === 0 && (
                <Grid  container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v1} controls>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v2} controls>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v3} controls>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v4} controls>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Grid>
                </Grid>
                )}
              </Box>
              <Box   role="tabpanel"
                  hidden={tabs !== 1}
                  id={`simple-tabpanel-${1}`}
                  aria-labelledby={`simple-tab-${1}`}
                    >
                {tabs === 1 && (
                <Grid  container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v1} controls>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v2} controls>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <video style={{width: "100%", height: "auto", borderRadius: 10}} poster={v3} controls>
                      <source src={video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Grid>
                </Grid>
                )}
              </Box>
        </Box>
    </Box>
  );
}
