import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  Paper,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Divider,
  Select,
  Menu,
  MenuItem,
  Chip,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Grid,
  TextField
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from "@material-ui/icons/Add";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import InboxIcon from "@material-ui/icons/Inbox";
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { avatar, elogo, bag } from "./assets";
import "./CustomisableUserProfiles.css"

// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController";
import Header from "../../../components/src/Header.web";
// Custom theme begins here
const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});
// Custom theme ends here

// Custom style begins here
const styles = {
  button: {
    borderRadius: 10,
    padding: '15px 60px',
  },
  inputs: {
    borderRadius: 10,
  },
};
// Custom style ends here


export default class UserMyProfileSettings extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <div style={{backgroundColor: "rgb(250, 250, 250)", overflowX: 'hidden'}}>
        <Header  bgColor="white" />
        <Container maxWidth="lg" style={{margin: "40px auto"}}>
          <Grid container spacing={4}>
            <Grid item md={3}>
               <Box className="card">
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem
                      classes={{ root: 'link', selected: 'selected' }}
                      button
                      selected={this.state.selectedIndex === 0}
                      onClick={(event) => this.handleListItemClick(event, 0)}
                    >
                      <ListItemIcon>
                        <PersonIcon  color={this.state.selectedIndex === 0? 'primary': 'inherit'} />
                      </ListItemIcon>
                      <ListItemText primary="My Profile" />
                    </ListItem>
                    <ListItem
                      classes={{ root: 'link'}}
                      button
                      selected={this.state.selectedIndex === 1}
                      onClick={(event) => this.handleListItemClick(event, 1)}
                    >
                      <ListItemIcon>
                        <SettingsIcon  />
                      </ListItemIcon>
                      <ListItemText primary="Settings" />
                    </ListItem>
                    <ListItem
                      classes={{ root: 'link-2', selected: 'selected-2' }}
                      style={{color: "red"}}
                      button
                      selected={this.state.selectedIndex === 2}
                      onClick={(event) => {
                        this.handleListItemClick(event, 2);
                        this.handleOpenLogout()
                      }}
                    >
                      <ListItemIcon>
                        <ExitToAppIcon  style={{color: "red"}} />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
               </Box>
               <Box my={3}>
                  <Button variant="contained" color="primary" size="large" fullWidth style={{...styles.button, color:'white',}} disableElevation>
                      Become a Mentor
                  </Button>
               </Box>
             </Grid>
              <Grid item md={9}>
                <Paper elevation={5} classes={{ rounded: "inputRadius" }} >
                <Box fontWeight="fontWeightBold" p={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start"
                }}>
                <Typography variant="h4" component="h2" style={{fontWeight:'bold',marginBottom: 20}}>
                  Settings
                </Typography>
                <Typography variant="h5" component="h2" style={{fontWeight:'bold', margin: "20px 0"}}>
                  Change Password
                </Typography>
                  <Box width={"100%"} my={2}>
                    <Typography variant="body1" style={{fontWeight:600}} component="h6">
                        Current Password
                    </Typography>
                    <TextField fullWidth  style={{...styles.inputs}} variant="outlined" placeholder="Current Password" />     
                  </Box>
                  <Box width={"100%"} my={2}>
                    <Typography variant="body1" style={{fontWeight:600}} component="h6">
                        New Password
                    </Typography>
                    <TextField fullWidth  style={{...styles.inputs}} variant="outlined" placeholder="New Password" />     
                  </Box>
                  <Box width={"100%"} my={2}>
                    <Typography variant="body1" style={{fontWeight:600}} component="h6">
                        Confirm New Password
                    </Typography>
                    <TextField fullWidth  style={{...styles.inputs}} variant="outlined" placeholder="Confirm New Password" />     
                  </Box>
                  <Button variant="contained" color="primary" size="large" style={{...styles.button, color:'white'}} disableElevation>
                    Update
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        </div>
    );
  }
}
