import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Box, Link, Button, InputBase} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { inspidate } from '../../blocks/landingpage/src/assets';
import "./SearchBox.css"

const useStyles = makeStyles({
  root: {
    background: "white",
    borderRadius: 10,
    padding: "5px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }, 
  icon: {
    height: "24px",
    width: "24px",
    padding: "10px",
    flexShrink: 0,
  },
  search: {
    padding: "10px", 
    appearance: "none",
    MozAppearance: "none",
    WebkitAppearance:"none",
    flexShrink: 0,
    outline: "none",
    border: "none",
    '::placeholder': {
      fontSize: "1.25rem",
    },
  },
  button: {
    borderRadius: 10,
    padding: '15px 60px',
    color: 'white',
    background: "#212121",
    flexShrink: 0,
    fontSize: "18px",
    '&:hover': {
      backgroundColor: '#212121',
      borderColor: '#212121',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#212121',
      borderColor: '#212121',
    },
  },
});
// block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
export default function SearchBox(props:any) {
  const classes = useStyles();
  return (
    <Box>
      <form className={classes.root}>   
          <svg aria-hidden="true" className={classes.icon} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
          <InputBase type="search" id="default-search" className={classes.search} placeholder="Find mentors around the globe " required />
          <Button variant="contained" className={classes.button}  size="large" disableElevation>
            Search
          </Button>
      </form>
    </Box>
  );
}
