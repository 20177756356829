import React from "react";
// Customizable Area Start
import { 
  Box, 
  Button, 
  IconButton,
  Paper, 
  Container, 
  Grid, 
  Menu,
  MenuItem,
  Popover, 
  Checkbox,
  Tabs,
  Tab,
  TextField,
  ListItem, 
  Card, 
  CardContent,
  ListItemAvatar, 
  Avatar, 
  ListItemText, 
  List } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {  newYorkExpress, airbnb, bonnie, jonathan, video, v1, v2, v3, v4, growthMarketing, productAnalytics } from "./assets";
import MarketPlaceCard from "../../../components/src/MarketplaceCard.web";
import Footer from "../../../components/src/Footer.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import StarIcon from '@material-ui/icons/Star';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import grey from '@material-ui/core/colors/grey';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = {
  filterButtons: {
    borderRadius: "6px",
    display: "flex",
    padding: "10px 15px",
    backgroundColor: "white",
    color: "#818181",
    justifyContent: "space-between",
    width: "100%"
  },
  popover: {
    margin: "10px 0",
  },
  card: {
    background: "white",
    margin: "25px 0", 
    borderRadius: 10,
    padding: "25px", 
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
  },
  displayFlex:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  expertiseIcons: {
    height: "100px",
    width: "100px",
    borderRadius: 10,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
  },
  tab:{
    minWidth: "auto",
    fontSize: "16px"
  },
  videoTab:{
    minWidth: "auto",
    padding: "6px 40px",
    fontSize: "16px",
    borderRadius: "30px",
    ':selected': {
      backgroundColor: "#00aeef",
      color:"white",  
    },
    '& > span': {
      display: "none",
    },
  },
  badge:{
    borderRadius: 20,
    padding:"0 10px",
    margin: '10px 0',
    color: "white",
    backgroundColor: "#2DA400",
    '&:hover': {
      backgroundColor: '#2DA400',
      borderColor: '#2DA400',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#2DA400',
      borderColor: '#2DA400',
    },
  },
  tabs: {
    borderBottom: "1px solid rgb(77 77 77 / 17%)" 
  },
  videoTabs: {
    borderRadius: "30px",
    backgroundColor: "#e0e0e0" 
  },
  displayFlexCenter:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";
import { url } from "inspector";
import Copyright from "../../../components/src/Copyright.web";
import SearchBoxBrowseMentor from "../../../components/src/SearchBoxBrowseMentor.web";
import Header from "../../../components/src/Header.web";
import MentorCard from "../../../components/src/MentorCard.web";
import { borderRadius } from "react-select/src/theme";
import MentorVideoCard from "../../../components/src/MentorVideosCard.web";

export default class MentorProfilePage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start  
    // Customizable Area End
  }

  render() {
    return (
      <>
      {/* Customizable Area Start */}
      <div style={{overflowX: 'hidden', backgroundColor: `${grey[50]}`}} >
      <Header  bgColor="white" />     
      <Container maxWidth="lg">
          <MentorCard forProfilePage={true} />
          {/* About Section Begins */}
          <Box style={{...styles.card}}>
            <Box>
              <Tabs indicatorColor="primary" style={{...styles.tabs}} textColor="primary" value={this.state.tabs} onChange={this.handleChange} aria-label="simple tabs example">
                <Tab style={{...styles.tab}} label="About" {...a11yProps(0)} />
                <Tab style={{...styles.tab}} label="Expertise" {...a11yProps(1)} />
                <Tab style={{...styles.tab}} label="Experience" {...a11yProps(2)} />
                <Tab style={{...styles.tab}} label="Videos" {...a11yProps(3)} />
                <Tab style={{...styles.tab}} label="Reviews" {...a11yProps(3)} />
                <Tab style={{...styles.tab}} label="Calendar" {...a11yProps(3)} />
              </Tabs>
             <Box my={3} color={"#4D4D4D"}>
                <Typography variant="body1"  paragraph={true}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi velit nobis eligendi! In ut saepe pariatur voluptatibus corporis commodi, explicabo beatae dolore perspiciatis ducimus recusandae iure, repudiandae quae dolores sequi iusto iste eos, dolorem nemo. Excepturi reprehenderit aperiam maxime molestias.
                </Typography>
                <Typography variant="body1"  paragraph={true}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, sapiente recusandae! Tempora, asperiores cum modi nihil ducimus atque omnis maxime, quidem ex non optio assumenda quisquam voluptates veritatis, illum voluptate nisi ea laboriosam autem! Provident nesciunt quod autem porro ducimus! Similique voluptatem tenetur laborum quod itaque natus facere iure magnam?
                 </Typography>
             </Box>
            </Box>
          </Box>
          {/* About Section Ends */}
          {/* Expertise Section Begins */}
           <Box style={{...styles.card}}>
            <Box>
              <Typography variant="h5" style={{fontWeight: 600, margin:"15px 0"}} component="h3">
                Expertise
              </Typography>
              <Grid  container spacing={4}>
                <Grid item xs={11}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} style={{margin: "10px 0", display:'flex', flexDirection:'row'}}>
                    <Card style={{...styles.expertiseIcons}} variant="outlined">
                      <CardContent>
                        <img src={growthMarketing} height={"50px"} width={"50px"} alt="Growth Marketing" />
                      </CardContent>
                      </Card>
                      <Box ml={3}>
                      <Typography variant="h6" component="h4">
                          Growth Marketing
                        </Typography>
                        <Typography variant="body1"  paragraph={true}  style={{color: "#4D4D4D"}}>
                          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi velit nobis eligendi! In ut saepe pariatur voluptatibus corporis commodi, explicabo beatae dolore perspiciatis ducimus recusandae iure, repudiandae quae dolores sequi iusto iste eos, dolorem nemo. Excepturi reprehenderit aperiam maxime molestias.
                          </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid  container spacing={4}>
                <Grid item xs={11}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} style={{margin: "10px 0", display:'flex', flexDirection:'row'}}>
                    <Card style={{...styles.expertiseIcons}} variant="outlined">
                      <CardContent>
                        <img src={productAnalytics} height={"50px"} width={"50px"} alt="Growth Marketing" />
                      </CardContent>
                      </Card>
                      <Box ml={3}>
                      <Typography variant="h6" component="h4">
                          Product Analytics
                        </Typography>
                        <Typography variant="body1"  paragraph={true} style={{color: "#4D4D4D"}}>
                          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi velit nobis eligendi! In ut saepe pariatur voluptatibus corporis commodi, explicabo beatae dolore perspiciatis ducimus recusandae iure, repudiandae quae dolores sequi iusto iste eos, dolorem nemo. Excepturi reprehenderit aperiam maxime molestias.
                          </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* Expertise Section Ends */}
          {/* Experience Section Begins */}
          <Box style={{...styles.card}}>
                <Box>
                  <Typography variant="h5" style={{fontWeight: 600, margin:"15px 0"}} component="h3">
                    Experience
                  </Typography>
                  <Grid  container spacing={4}>
                    <Grid item xs={11}>
                      <Grid container spacing={3}>
                        <Grid item xs={5} style={{margin: "10px 0", display:'flex', flexDirection:'row'}}>
                          <img src={airbnb} height={"100px"} width={"100px"} alt="airbnb" />
                          <Box ml={3}>
                          <Typography variant="h6" component="h4" >
                              Senior HR, Delhi
                            </Typography>
                            <Typography variant="body1"  component="h6" style={{color: "#4D4D4D"}}>
                              Hexa Solutions
                            </Typography>
                            <Typography variant="body1"  component="h6" style={{color: "#4D4D4D"}}>
                              May 2008 - Present
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography variant="body1"  paragraph={true} style={{color: "#4D4D4D"}}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi velit nobis eligendi! In ut saepe pariatur voluptatibus corporis commodi, explicabo beatae dolore perspiciatis ducimus recusandae iure, repudiandae quae dolores sequi iusto iste eos, dolorem nemo. Excepturi reprehenderit aperiam maxime molestias.
                  </Typography>
              </Box>
              <Box mb={5}>
              <Grid  container spacing={4}>
                  <Grid item xs={11}>
                    <Grid container spacing={3}>
                      <Grid item xs={5} style={{margin: "10px 0", display:'flex', flexDirection:'row'}}>
                        <img src={newYorkExpress} height={"100px"} width={"100px"} alt="Bag" />
                        <Box ml={3}>
                        <Typography  variant="h6" component="h4">
                            Trainee HR Manager, Delhi
                          </Typography>
                          <Typography  variant="body1" component="h6" style={{color: "#4D4D4D"}}>
                          New York Express 
                          </Typography>
                          <Typography  variant="body1" component="h6" style={{color: "#4D4D4D"}}>
                            May 2006 - 2008
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography variant="body1"  paragraph={true} style={{color: "#4D4D4D"}}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi velit nobis eligendi! In ut saepe pariatur voluptatibus corporis commodi, explicabo beatae dolore perspiciatis ducimus recusandae iure, repudiandae quae dolores sequi iusto iste eos, dolorem nemo. Excepturi reprehenderit aperiam maxime molestias.
                  </Typography>
              </Box>
          </Box>
          {/* Experience Section Ends */}
          {/* Videos Section Begins */}
          <MentorVideoCard />
          {/* Videos Section Ends */}
          {/* Reviews Section Begins */}
          <Box style={{...styles.card}}>
            <Box>
                <Box style={{...styles.displayFlex}}>
                 <Typography variant="h5" style={{fontWeight: 600, margin:"15px 0"}} component="h3">
                    Reviews
                  </Typography>
                  <Button variant="contained" style={{color: "black", background:"white"}}  endIcon={<ExpandMoreIcon />} disableElevation>
                    Recent
                  </Button>
                 </Box>
                  <Grid  container spacing={4}>
                    <Grid item xs={11}>
                      <Grid container spacing={3}>
                        <Grid item xs={5} style={{margin: "10px 0", display:'flex', flexDirection:'row'}}>
                          <img src={bonnie} height={"70px"} width={"70px"} alt="bonnie" />
                          <Box ml={3}>
                          <Typography variant="h6" component="h4">
                              Bonnie R. Miller
                            </Typography>
                            <Typography variant="body2"  component="h6" style={{color: "#4D4D4D"}}>
                              20 Mar 2020, 10:30 AM
                            </Typography>
                            <Button
                              variant="contained"
                              color="default"
                              style={{...styles.badge}}
                              startIcon={<StarIcon />}
                              disableElevation
                            >
                              4.6
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography variant="body1"  paragraph={true} style={{color: "#4D4D4D"}}>
                    Foti doesn't only talk the talk, he walks the walk. A true gentleman and power-house.  
                  </Typography>
              </Box>
              <Box mb={5}>
                <Grid  container spacing={4}>
                    <Grid item xs={11}>
                      <Grid container spacing={3}>
                        <Grid item xs={5} style={{margin: "10px 0", display:'flex', flexDirection:'row'}}>
                          <img src={jonathan} height={"70px"} width={"70px"} alt="Bag" />
                          <Box ml={3}>
                          <Typography  variant="h6" component="h4">
                            Johnathon Parker
                            </Typography>
                            <Typography  variant="body2" component="h6" style={{color: "#4D4D4D"}}>
                              20 Mar 2020, 10:30 AM
                            </Typography>
                            <Button
                              variant="contained"
                              color="default"
                              style={{...styles.badge}}
                              startIcon={<StarIcon />}
                              disableElevation
                            >
                              4.6
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography variant="body1"  paragraph={true} style={{color: "#4D4D4D"}}>
                  Amazing and super helpful session with Foti as always. He shared a golden strategy with me and I got out of the call with a clear idea on what I can take action on now. Can't get more actionable! 
                  </Typography>
              </Box>
              <Box style={{...styles.displayFlexCenter}}>
                  <Button variant="outlined" style={{color: "black", padding: "10px 30px" }}  disableElevation>
                    VIEW ALL
                  </Button>
              </Box>
          </Box>
          {/* Reviews Section Ends */}
      </Container>
    </div>
      {/* Customizable Area End */}
      </>
    );
  }
}