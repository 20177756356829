import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Grid,
  Button,
  Input,
  Paper,
  Typography,
  Link,
  Avatar,
  CssBaseline,
  FormControl,
  FormControlLabel,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  LoginSocialLinkedin,
} from 'reactjs-social-login';
import { inspidate, login} from './assets';

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

// Custom theme begins here
const theme = createTheme({
  palette: {
    primary: {
      main: '#00aeef'
    },
    secondary: {
      main: '#4D4D4D'
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});
// Custom theme ends here

// Custom style begins here
const styles = {
  button: {
    borderRadius: 10,
    padding: '15px 60px',
  },
  inputs: {
    borderRadius: 10,
  },
};

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
            <Grid container component="main" style={{ height: '100vh', padding: '20px', overflowX: 'hidden', display: 'flex', alignItems: 'start',}} className={''}>
          <Grid item xs={false} sm={4} md={7} className={''}>
          <Box>
              <img src={login} alt="" style={{objectFit: 'contain', width: '100%',borderRadius: '40px'}} />
          </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '96%', padding: '30px'}}>
              <Box mb={3} >
                <img src={inspidate} alt="" style={{height: '70px'}} />
              </Box>
              <Typography component="h1" variant="h4" style={{fontWeight: 600}}>
              Welcome Back !
              </Typography>
              <Typography component="h3" variant="h6" style={{fontWeight: 400, marginTop: '10px'}}>
              Login to your account
              </Typography>
              <form className={''} noValidate>
                  <Box mt={3}>
                      <Typography variant="body1" style={{fontWeight:600}} component="h6">
                        Email ID
                      </Typography>
                      <OutlinedInput fullWidth className='inputRadius'  value={this.state.email}
                      onChange={(e) => this.setEmail(e.target.value)} placeholder="Email ID" />
                  </Box>
                  <Box mt={3}>
                      <Typography variant="body1" style={{fontWeight:600}} component="h6">
                        Password
                      </Typography>
                      <OutlinedInput className='inputRadius' placeholder="Password"
                      data-test-id="txtInputPassword"
                      type={this.state.enablePasswordField ? "password" : "text"}
                      fullWidth={true}
                      value={this.state.password}
                      onChange={(e) => this.setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {this.state.enablePasswordField ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      } />
                  </Box>
                <Grid container style={{display: 'flex', alignItems: 'center', padding: '15px 0'}}>
                  <Grid item xs>
                    <FormControlLabel
                    control={<Checkbox value="remember" color="default"  
                    data-test-id={"btnRememberMe"}
                    onClick={() =>
                      this.setRememberMe(!this.state.checkedRememberMe)
                    }
                    checked={this.state.checkedRememberMe} />}
                    label="Keep me signed in"
                  />
                  </Grid>
                  <Grid item>
                    <Link href="#"  onClick={() => this.goToForgotPassword()} variant="body2">
                      Forgot Password?
                    </Link>
                  </Grid>
                </Grid>
                <Box>
                  <Button variant="contained" color="primary" onClick={() => this.doEmailLogIn()} fullWidth size="large" style={{...styles.button, color:'white'}} disableElevation>
                    Login
                  </Button>
                  <Box my={2}>
                  <div className="items-center flex justify-between">
                    <span className="h-[1px] w-full bg-slate-300"></span>
                    <span className="mx-3 text-slate-300"> OR </span>
                    <span className="h-[1px] w-full bg-slate-300"></span>
                  </div>
                  </Box>
                  <LoginSocialLinkedin
                      client_id={'86rq3i7egbh9ag'}
                      client_secret={'zHSBqHny7AF4XyO9'}
                      redirect_uri={"/CompleteProfile"}
                      onLoginStart={()=>{console.log("Login Start")} }
                      onResolve={({provider, data}) => {
                        console.log("LinkedIn logged IN: ", provider, data)
                      }}
                      onReject={(err: any) => {
                        console.log(err);
                      }}>
                        <Button variant="outlined"  onClick={() => this.handleAuthorization} startIcon={<LinkedInIcon  style={{fontSize: 30}} />} fullWidth size="large" style={{...styles.button, color: "#0e76a8", borderColor:"#0e76a8"}} disableElevation>
                          Continue With LinkedIn
                        </Button>
                      </LoginSocialLinkedin>
                </Box>
                  <Box mt={3} style={{textAlign: 'center'}}>
                    <Link href="/EmailAccountRegistration" color="secondary" variant="body2" >
                      {"Already have an account?"}
                      <Link href="/EmailAccountRegistration" color="secondary" variant="body1" style={{fontWeight:600, color: '#212121'}}>
                      {" Signup"}
                    </Link>
                    </Link>
                  </Box>
              </form>
            </div>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}
