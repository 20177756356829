import React from "react";
// Customizable Area Start
import { Box, Button, Container, Link, Grid, ListItem, ListItemAvatar, Avatar, ListItemText, List } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {  icon1, icon2, icon3, icon4, icon5, icon6, video, videoPoster, listArrow, cta, hero } from "./assets";
import MarketPlaceCard from "../../../components/src/MarketplaceCard.web";
import Footer from "../../../components/src/Footer.web";
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";
import { url } from "inspector";
import Copyright from "../../../components/src/Copyright.web";
import SearchBox from "../../../components/src/SearchBox.web";
import Header from "../../../components/src/Header.web";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <>
      {/* Customizable Area Start */}
      <div className={""} style={{overflowX: 'hidden'}}>
      <Header />
      {/* Hero Section Begins */}
      <Box display={'block'} style={{backgroundImage: `url(${hero})`, backgroundSize:'cover' }}>
        {/* <img src={ hero } style={{width: "100%", objectFit: "cover", objectPosition: "center"}} alt="" /> */}
        <Container maxWidth="lg" style={{position: 'relative'}}>
            <Grid container spacing={6} >
              <Grid item xs={12} md={6} style={{margin: "100px 0"}} >
              <Typography variant="h2" component="h1" style={{fontWeight:700, margin: "36px 0"}}>
              Join the INSPIdate Community!
            </Typography>
            <Typography variant="h6" component="h2" style={{fontWeight:400, color: "#4D4D4D", margin: "36px 0", maxWidth: "350px"}}>
              Learn from the most inspiring personalities in the world.
            </Typography>
            <Box>
              <SearchBox />
            </Box>
              </Grid>
            </Grid>
        </Container>
      </Box>
      {/* Hero Section Ends */}
      {/* Explore MarketPlace Section Begins */}
      <Box position={'relative'} mt={10}>
        <Container maxWidth="lg" style={{padding:24}}>
            <Typography variant="h4" component="h2" style={{fontWeight:600}}>
              Explore the marketplace
            </Typography>
            <Grid container spacing={6}   style={{marginTop: 10}}>
              <Grid item xs={6} sm={4} md={2} >
                <MarketPlaceCard icon={icon1} title="Growth Marketing" />
              </Grid>
              <Grid item xs={6} sm={4} md={2} >
                <MarketPlaceCard icon={icon2} title="Product Analytics" />
              </Grid>
              <Grid item xs={6} sm={4} md={2} >
                <MarketPlaceCard icon={icon3} title="Go to Market Strategy" />
              </Grid>
              <Grid item xs={6} sm={4} md={2} >
                <MarketPlaceCard icon={icon4} title="Product Launches" />
              </Grid>
              <Grid item xs={6} sm={4} md={2} >
                <MarketPlaceCard icon={icon5} title="Startup Funding"/>
              </Grid>
              <Grid item xs={6} sm={4} md={2} >
                <MarketPlaceCard icon={icon6} title="SEO"/>
              </Grid>
            </Grid>
        </Container>
      </Box>
      {/* Explore MarketPlace Section Ends */}
        {/* Grow Up Your Business Section Begins */}
        <Box position={'relative'}>
        <Container maxWidth="lg" style={{padding:24}}>
            <Grid container spacing={6} style={{margin: "80px 0"}}>
              <Grid item xs={12} md={6} >
                <video style={{width: "100%", height: "auto"}} poster={videoPoster} controls>
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>
                <Grid item xs={12} md={6} >
                  <Typography variant="h4" component="h2" style={{fontWeight:600}}>
                    Grow up your business with marketing
                  </Typography>
                  <Box mt={5}>
                    <List>
                      <ListItem alignItems="flex-start" style={{paddingLeft: 0}}>
                          <ListItemAvatar style={{minWidth:'30px'}}>
                            <Avatar style={{height: "20px", width: "20px"}} alt="Nothing" src={ listArrow } />
                          </ListItemAvatar>
                          <Typography variant="body1" style={{fontWeight:600, fontSize: '20px'}}>
                              Increase your visitors
                          </Typography>
                        </ListItem>
                        <Typography variant="body1" color="secondary" style={{marginTop: '10px'}}>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab ad aperiam ipsum nesciunt optio dolores laboriosam accusamus cumque quidem nobis?
                          </Typography>
                      </List>
                      <List>
                      <ListItem alignItems="flex-start" style={{paddingLeft: 0}}>
                          <ListItemAvatar style={{minWidth:'30px'}}>
                            <Avatar style={{height: "20px", width: "20px"}} alt="Nothing" src={ listArrow } />
                          </ListItemAvatar>
                          <Typography variant="body1" style={{fontWeight:600, fontSize: '20px'}}>
                              Increase your visitors
                          </Typography>
                        </ListItem>
                        <Typography variant="body1" color="secondary" style={{marginTop: '10px'}}>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ab ad aperiam ipsum nesciunt optio dolores laboriosam accusamus cumque quidem nobis?
                          </Typography>
                      </List>
                  </Box>
                </Grid>
            </Grid>
            <Grid container spacing={6} alignItems="center" style={{margin: '60px 0', borderRadius: 15, width: '100%',  boxShadow: `rgba(0, 174, 239, 0.4) 0px 0px 90px`, backgroundImage: `url(${cta})`, backgroundPosition: 'center', backgroundSize: "cover"}}>
              <Grid item xs={12} md={9}>
                  <Box m={3}>
                      <Typography variant="h4" component="h2" style={{fontWeight:600, color: 'white'}}>
                      Ready To Get Growing?
                      </Typography>
                      <Typography variant="body1"  style={{fontWeight:400, color: 'white', marginTop: 15}}>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quam nam, impedit.
                      </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box m={3}>
                    <Button variant="contained" color="primary" style={{backgroundColor: 'white',  borderRadius: 10, padding: '15px 60px'}}  size="large"   disableElevation>
                      CONTINUE
                    </Button>
                  </Box>
                </Grid>
            </Grid>
            <Footer />
        </Container>
      </Box>
      {/* Grow Up Your Business Section Ends */}
      <span className="h-[1px] w-full bg-slate-300" style={{display:'block', margin: "10px 0"}}></span>
      <Container maxWidth="lg">
      <Copyright style={{padding: "10px 0"}} />
      </Container>
    </div>
      {/* Customizable Area End */}
      </>
    );
  }
}