import React from 'react';
import { Box, Link,Paper,  AppBar, Container, Toolbar, Button} from '@material-ui/core';
import cyan from '@material-ui/core/colors/cyan';

import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { inspidate } from '../../blocks/landingpage/src/assets';

export default function Header(props:any) {
    const barBg = cyan[50];

  return (
        <Paper variant='outlined'>
            <AppBar position="relative" elevation={0} style={{ backgroundColor: props.bgColor || barBg, padding: '10px 0'}}>
                <Container maxWidth="lg">
                    <Toolbar  style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                        <Box mr={3}>
                            <img src={inspidate} alt="" style={{height: '50px'}} />
                        </Box>
                        <Box>
                            <Button color="primary" href='/BrowseMentorPage'  startIcon={<GroupOutlinedIcon />} variant="outlined" > Browse Mentors</Button>
                        </Box>
                        </Box>
                        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                        <Box mr={3}>
                            <Link color="secondary" href="/EmailAccountLoginBlock">Log In</Link>
                        </Box>
                        <Box>
                            <Button color="primary"  endIcon={<ArrowRightAltIcon />} style={{color: 'white'}} variant="contained" disableElevation> Get Started</Button>
                        </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Paper>
  );
}
