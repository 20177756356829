import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  Chip,
  Grid,
  TextField
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from "@material-ui/icons/Add";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import InboxIcon from "@material-ui/icons/Inbox";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { avatar, elogo, bag } from "./assets";
import "./CustomisableUserProfiles.css"

// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController";
import Header from "../../../components/src/Header.web";
// Custom theme begins here
const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});
// Custom theme ends here
const industries = [
  'Industry1',
  'Industry2',
  'Industry3',
  'Industry4',
  'Industry5',
];
const interests = [
  'UI/UX',
  'Copywriting',
  'Frontend Development',
  'Backend Development',
  'Fullstack Development',
];
// Custom style begins here
const styles = {
  button: {
    borderRadius: 10,
    padding: '15px 60px',
  },
  inputs: {
    borderRadius: 10,
  },
};
// Custom style ends here


export default class BecomeMentor extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <div style={{backgroundColor: "rgb(250, 250, 250)", overflowX: 'hidden'}}>
        <Header  bgColor="white" />
        <Container maxWidth="lg" style={{margin: "40px auto"}}>
          <Grid container spacing={4}>
              <Grid item md={12}>
                <Paper elevation={5} classes={{ rounded: "inputRadius" }} >
                <Box fontWeight="fontWeightBold" p={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start"
                }}>
                <Typography variant="h5" component="h2" style={{fontWeight:'bold', margin: "20px 0"}}>
                  Become a mentor
                </Typography>
                  <Box width={"100%"} my={2}>
                  <Typography variant="body1" style={{fontWeight:600}} component="h6">
                      Industry
                      </Typography>
                      <FormControl  variant="outlined" style={{width: "100%"}}>
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          label="Industry"
                          value={this.state.personName}
                          onChange={this.setPersonName}  
                          IconComponent={ ExpandMoreIcon }
                          input={  <OutlinedInput placeholder="Industry" id="select-multiple-chip" />}
                        >
                        {industries.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                  </Box>
                 <Grid container spacing={4}>
                    <Grid item md={6}>
                      <Typography variant="body1" style={{fontWeight:600}} component="h6">
                        Display Name
                      </Typography>
                      <TextField fullWidth className='inputRadius' variant="outlined" placeholder="Display Name" />
                      </Grid>
                      <Grid item md={6}>
                        <Typography variant="body1" style={{fontWeight:600}} component="h6">
                        Title
                      </Typography>
                      <TextField fullWidth className='inputRadius' variant="outlined" placeholder="Title" />
                    </Grid>
                 </Grid>
                 <Box width={"100%"} mt={3}>
                  <Typography variant="body1" style={{fontWeight:600}} component="h6">
                    Bio
                  </Typography>
                  <TextField fullWidth minRows={4} multiline className='inputRadius' variant="outlined" placeholder="Bio" />
                 </Box>
                 <Box  width={"100%"} my={3}>
                  <Typography variant="body1" style={{fontWeight:600}} component="h6">
                    Expertise
                  </Typography>
                  <FormControl fullWidth  variant="outlined">
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      label="Expertise"
                      fullWidth
                      IconComponent={ ExpandMoreIcon }
                      variant="outlined"
                      value={this.state.interests}
                      onChange={this.setInterests}  
                      input={<OutlinedInput placeholder="Expertise" fullWidth id="select-multiple-chip" />}
                    >
                    {interests.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                    <Box>
                    {(this.state.interests as string[]).map((value) => (
                        <Chip key={value} label={value} variant="outlined"
                        size="small"
                        color="primary"
                        onDelete={()=>{console.log("Delete Chip")}}
                        deleteIcon={<CancelIcon />} style={{marginRight: '10px', marginTop: '10px', padding: '12px', fontSize:'14px', height:'40px', borderRadius: '5px'}} />
                      ))}
                    </Box>
                  </Box>
                  <Button variant="contained" color="primary" size="large" style={{...styles.button, color:'white'}} disableElevation>
                    Next
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        </div>
    );
  }
}
