import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Grid,
  Button,
  Input,
  Paper,
  Typography,
  Link,
  Avatar,
  CssBaseline,
  FormControlLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { inspidate, login} from './assets';
require('./EmailAccountRegistration.css');

// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#00aeef'
    },
    secondary: {
      main: '#4D4D4D'
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});

const styles = {
  button: {
    borderRadius: 10,
    padding: '15px 60px',
  },
  inputs: {
    borderRadius: 10,
  },
};
// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
       // Required for all blocks
       <ThemeProvider theme={theme} >
        <Grid container component="main" style={{ height: '100vh', padding: '20px', overflowX: 'hidden', display: 'flex', alignItems: 'start',}} className={''}>
          <Grid item xs={false} sm={4} md={7} className={''}>
            <Box>
              <img src={login} alt="" style={{objectFit: 'contain', width: '100%',borderRadius: '40px'}} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '96%', padding: '30px'}}>
              <Box mb={3} >
                  <img src={inspidate} alt="" style={{height: '70px'}} />
              </Box>
              <Typography component="h1" variant="h4" style={{fontWeight: 600}}>
                  Welcome !
              </Typography>
              <Typography component="h3" variant="h6" style={{fontWeight: 400, marginTop: '10px'}}>
                  Register your account
              </Typography>
              <form className={''} noValidate>
                  <Box mt={3}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Box>
                          <Typography variant="body1" style={{fontWeight:600}} component="h6">
                            First Name
                          </Typography>
                          <OutlinedInput fullWidth className='inputRadius' {...this.txtInputFirstNamePrpos} placeholder="First Name" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}> 
                        <Box>
                          <Typography variant="body1" style={{fontWeight:600}} component="h6">
                            Last Name
                          </Typography>
                          <OutlinedInput fullWidth className='inputRadius' {...this.txtInputLastNamePrpos}  placeholder="Last Name" />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={3} style={{width: '100%'}}>
                      <Typography variant="body1" style={{fontWeight:600}} component="h6">
                        Email ID
                      </Typography>
                      <OutlinedInput className='inputRadius' fullWidth {...this.txtInputEmailPrpos} placeholder="Email ID" data-test-id="txtInputPassword" />
                  </Box>
                  <Box mt={3} style={{width: '100%'}}>
                      <Typography variant="body1" style={{fontWeight:600}} component="h6">
                        Password
                      </Typography>
                      <OutlinedInput type={this.state.enablePasswordField ? "password" : "text"} className='inputRadius' fullWidth {...this.txtInputPasswordProps}  endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        } placeholder="Password" data-test-id="txtInputPassword" />
                  </Box>
                  <Box mt={3} style={{width: '100%'}}>
                      <Typography variant="body1" style={{fontWeight:600}} component="h6">
                        Confirm Password
                      </Typography>
                      <OutlinedInput type={this.state.enablePasswordField ? "password" : "text"} className='inputRadius' fullWidth   {...this.txtInputConfirmPasswordProps} 
                       endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                          >
                            {this.state.enablePasswordField ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      } placeholder="Confirm Password" data-test-id="txtInputPassword" />
                  </Box>
                  <Box my={3} style={{textAlign: 'left'}}>
                  <FormControlLabel
                      control={<Checkbox value="remember" color="default" 
                      data-test-id={"btnRememberMe"} className="rounded_checkbox" style={{borderRadius: "999px"}} />}
                      label={
                        <Typography variant="body1"  component="h6" >
                        I accept the 
                        <Link href="#" { ...this.btnLegalPrivacyPolicyProps } variant="body1" style={{fontWeight:600, color: '#212121', textDecoration: 'underline'}} >
                          {" Terms of Use"} 
                        </Link><span style={{margin: '0 5px'}}>and</span> 
                        <Link href="#" { ...this.btnLegalPrivacyPolicyProps } variant="body1" style={{fontWeight:600, color: '#212121',  textDecoration: 'underline'}} >
                          {"Privacy Policy"}
                        </Link>
                        </Typography>
                      }
                /> 
                  </Box>
                  <Box>
                    <Button variant="contained" color="primary" {...this.btnSignUpProps} fullWidth size="large" style={{...styles.button, color:'white'}} disableElevation>
                      Sign Up
                    </Button>
                    <Box my={2}>
                      <div className="items-center flex justify-between">
                        <span className="h-[1px] w-full bg-slate-300"></span>
                        <span className="mx-3 text-slate-300"> OR </span>
                        <span className="h-[1px] w-full bg-slate-300"></span>
                      </div>
                    </Box>
                    <Button variant="outlined"   startIcon={<LinkedInIcon  style={{fontSize: 30}} />} fullWidth size="large" style={{...styles.button, color: "#0e76a8", borderColor:"#0e76a8"}} disableElevation>
                    Continue With LinkedIn
                  </Button>
                  </Box>
                  <Box mt={3} style={{textAlign: 'center'}}>
                  <Typography variant="body1"  component="h6" >
                  Already have an account? 
                    <Link href="/EmailAccountLoginBlock" color="secondary" variant="body1" style={{fontWeight:600, color: '#212121'}}>
                      {" Login"}
                    </Link>
                  </Typography>
                  </Box>
              </form>
            </div>
          </Grid>
        </Grid>
       </ThemeProvider>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    // this.getValidations();
    // Customizable Area End
  }
}