import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Grid,
  Button,
  Input,
  Paper,
  Typography,
  Link,
  Avatar,
  CssBaseline,
  FormControlLabel,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MailIcon from '@material-ui/icons/Mail';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { inspidate, login } from './assets';
// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#00aeef'
    },
    secondary: {
      main: '#4D4D4D'
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});

const styles = {
  button: {
    borderRadius: 10,
    padding: '15px 60px',
  },
  inputs: {
    borderRadius: 10,
  },
};
// Customizable Area End

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
       // Required for all blocks
       <ThemeProvider theme={theme}>
       <Grid container component="main" style={{ height: '100vh', padding: '20px', overflowX: 'hidden', display: 'flex', alignItems: 'start',}} className={''}>
     <Grid item xs={false} sm={4} md={7} className={''}>
     <Box>
         <img src={login} alt="" style={{objectFit: 'contain', width: '100%',borderRadius: '40px'}} />
     </Box>
     </Grid>
     <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
       <div style={{width: '96%', padding: '30px'}}>
         <Box mb={3} >
           <img src={inspidate} alt="" style={{height: '70px'}} />
         </Box>
         <Typography component="h1" variant="h4" style={{fontWeight: 600}}>
        Reset Password 
         </Typography>
         <Typography component="h3" variant="h6" style={{fontWeight: 400, marginTop: '10px'}}>
         Update your password
         </Typography>
         <form className={''} noValidate>
             <Box mt={3}>
                 <Typography variant="body1" style={{fontWeight:600}} component="h6">
                  New Password
                 </Typography>
                 <TextField className='inputRadius' variant="outlined" placeholder="New Password"
                 data-test-id="txtInputPassword"
                 type={"password"}
                 fullWidth={true}
                //  inputProps={{
                //    endAdornment:(
                //      <InputAdornment position="end">
                //        <IconButton
                //          aria-label="toggle password visibility"
                //          edge="end"
                //        >
                //          {this.state.enablePasswordField ? (
                //            <Visibility />
                //          ) : (
                //            <VisibilityOff />
                //          )}
                //        </IconButton>
                //      </InputAdornment>
                //    )
                //  }}
                 />
             </Box>
             <Box mt={3}>
                 <Typography variant="body1" style={{fontWeight:600}} component="h6">
                   Confirm New Password
                 </Typography>
                 <TextField className='inputRadius' variant="outlined" placeholder="Confirm New Password"
                 data-test-id="txtInputConfirmNewPassword"
                 type={"password"}
                 fullWidth={true}
                //  inputProps={{
                //    endAdornment:(
                //      <InputAdornment position="end">
                //        <IconButton
                //          aria-label="toggle password visibility"
                //          edge="end"
                //        >
                //          {this.state.enableConfirm New PasswordField ? (
                //            <Visibility />
                //          ) : (
                //            <VisibilityOff />
                //          )}
                //        </IconButton>
                //      </InputAdornment>
                //    )
                //  }}
                 />
             </Box>


           <Box mt={3}>
             <Button variant="contained" color="primary" fullWidth size="large" style={{...styles.button, color:'white'}} disableElevation>
               Submit
             </Button>
           </Box>
          
         </form>
       </div>
     </Grid>
   </Grid>
 </ThemeProvider>
    );
  }

}