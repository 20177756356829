import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  Paper,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Divider,
  Select,
  Menu,
  MenuItem,
  Chip,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Grid,
  OutlinedInput
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from "@material-ui/icons/Add";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import InboxIcon from "@material-ui/icons/Inbox";
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { avatar, elogo, bag } from "./assets";
import "./CustomisableUserProfiles.css"

// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController";
import Header from "../../../components/src/Header.web";
// Custom theme begins here
const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});
// Custom theme ends here

// Custom style begins here
const styles = {
  button: {
    borderRadius: 10,
    padding: '15px 60px',
  },
  inputs: {
    borderRadius: 10,
  },
};
const languages = [
  'Hindi',
  'English',
  'Sanskrit',
  'Tamil',
  'Telugu',
];

const interests = [
  'UI/UX',
  'Copywriting',
  'Frontend Development',
  'Backend Development',
  'Fullstack Development',
];

// Custom style ends here


export default class UserMyProfile extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <div style={{backgroundColor: "rgb(250, 250, 250)", overflowX: 'hidden'}}>
        <Header  bgColor="white" />
        <Container maxWidth="lg" style={{margin: "40px auto"}}>
          <Grid container spacing={4}>
            <Grid item md={3}>
               <Box className="card">
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem
                      classes={{ root: 'link', selected: 'selected' }}
                      button
                      selected={this.state.selectedIndex === 0}
                      onClick={(event) => this.handleListItemClick(event, 0)}
                    >
                      <ListItemIcon>
                        <PersonIcon  color={this.state.selectedIndex === 0? 'primary': 'inherit'} />
                      </ListItemIcon>
                      <ListItemText primary="My Profile" />
                    </ListItem>
                    <ListItem
                      classes={{ root: 'link'}}
                      button
                      selected={this.state.selectedIndex === 1}
                      onClick={(event) => this.handleListItemClick(event, 1)}
                    >
                      <ListItemIcon>
                        <SettingsIcon  />
                      </ListItemIcon>
                      <ListItemText primary="Settings" />
                    </ListItem>
                    <ListItem
                      classes={{ root: 'link-2', selected: 'selected-2' }}
                      style={{color: "red"}}
                      button
                      selected={this.state.selectedIndex === 2}
                      onClick={(event) => {
                        this.handleListItemClick(event, 2);
                        this.handleOpenLogout()
                      }}
                    >
                      <ListItemIcon>
                        <ExitToAppIcon  style={{color: "red"}} />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
               </Box>
               <Box my={3}>
                  <Button variant="contained" color="primary" size="large" fullWidth style={{...styles.button, color:'white',}} disableElevation>
                      Become a Mentor
                  </Button>
               </Box>
             </Grid>
            <Grid item md={9}>
                <Paper classes={{root: 'shadnow-lg'}}>
                <Box fontWeight="fontWeightBold" p={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start"
                }}>
                <Typography variant="h4" component="h2" style={{fontWeight:'bold'}}>
                  Complete Your Profile
                </Typography>
                <Box   sx={{ width: 100, height: 100, margin: '30px 0' }}>
                  <img src={avatar} height={"100px"} width={"100px"} alt="helo" />
                </Box>
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <Typography variant="body1" style={{fontWeight:500}} component="h6">
                    First Name
                  </Typography>
                  <OutlinedInput fullWidth className='inputRadius' placeholder="First Name" />
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body1" style={{fontWeight:500}} component="h6">
                    Last Name
                  </Typography>
                  <OutlinedInput fullWidth className='inputRadius' placeholder="Last Name" />
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body1" style={{fontWeight:500}} component="h6">
                    Role
                  </Typography>
                  <OutlinedInput fullWidth className='inputRadius' placeholder="Role" />
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body1" style={{fontWeight:500}} component="h6">
                    Professional Experience <span style={{fontWeight:400, fontSize: '12px'}}>(Years)</span>
                  </Typography>
                  <OutlinedInput fullWidth className='inputRadius' placeholder="Professional Experience" />
                  </Grid>
                  <Grid item xs={12}>
                  <Box mt={3}>
                  <Typography variant="body1" style={{fontWeight:500}} component="h6">
                  Interests
                  </Typography>
                  <FormControl  variant="outlined" className={'helo'} style={{width: "100%"}}>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      variant="outlined"
                      IconComponent={ExpandMoreIcon}
                      value={this.state.interests}
                      onChange={this.setInterests}  
                      input={<OutlinedInput className="inputRadius" id="select-multiple-chip" />}
                    >
                    {interests.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                    <Box>
                    {(this.state.interests as string[]).map((value) => (
                        <Chip key={value} label={value} variant="outlined"
                        size="small"
                        color="primary"
                        onDelete={()=>{console.log("Delete Chip")}}
                        deleteIcon={<CancelIcon />} style={{marginRight: '10px', marginTop: '10px', padding: '12px', fontSize:'14px', height:'40px', borderRadius: '5px'}} />
                      ))}
                    </Box>
                  </Box>
                  </Grid> 
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{fontWeight:500}} component="h6">
                    Are you a mentor?
                    </Typography>
                    <Box>
                    <RadioGroup row aria-label="mentor" name="mentor" value={this.state.mentor} onChange={this.handleMentorChange}>
                      <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                    </Box>
                  </Grid>
                </Grid>
                <hr />
                <Divider />
                <Box>
                    <Typography variant="h5" style={{fontWeight: 600, margin:"15px 0"}} component="h3">
                      Experience
                    </Typography>
                    <Grid  container spacing={4}>
                      <Grid item xs={11}>
                        <Grid container spacing={3}>
                          <Grid item xs={11}  style={{margin: "10px 0", display:'flex', flexDirection:'row'}}>
                            <img src={bag} height={"100px"} width={"100px"} alt="Bag" />
                            <Box ml={3}>
                            <Typography variant="h6" component="h4">
                                Senior HR, Delhi
                              </Typography>
                              <Typography variant="body1"  component="h6">
                                Hexa Solutions
                              </Typography>
                              <Typography variant="body1"  component="h6">
                                May 2008 - Present
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1}>
                      <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Typography variant="body1"  paragraph={true}>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi velit nobis eligendi! In ut saepe pariatur voluptatibus corporis commodi, explicabo beatae dolore perspiciatis ducimus recusandae iure, repudiandae quae dolores sequi iusto iste eos, dolorem nemo. Excepturi reprehenderit aperiam maxime molestias.
                    </Typography>
                </Box>
                <Box mb={5}>
                <Grid  container spacing={4}>
                    <Grid item xs={11}>
                      <Grid container spacing={3}>
                        <Grid item xs={11} style={{margin: "10px 0", display:'flex', flexDirection:'row'}}>
                          <img src={elogo} height={"100px"} width={"100px"} alt="Bag" />
                          <Box ml={3}>
                          <Typography  variant="h6" component="h4">
                              Trainee HR Manager, Delhi
                            </Typography>
                            <Typography  variant="body1" component="h6">
                            New York Express 
                            </Typography>
                            <Typography  variant="body1" component="h6">
                              May 2006 - 2008
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                    <IconButton
                        aria-label="more"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={this.setAnchorEl}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                      >
                        <MenuItem onClick={
                          ()=>{
                            this.handleOpenExpEdit(); 
                            this.handleClose() }
                        }>Edit</MenuItem>
                        <MenuItem onClick={()=>{
                          this.handleOpenExp(); 
                          this.handleClose() }}>Delete</MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                   {/* Edit Dialog Begins */}
                   <Dialog
                    fullWidth
                    maxWidth={'sm'}
                    open={this.state.openExpEdit}
                    onClose={this.handleCloseExp}
                    aria-labelledby="max-width-dialog-title"
                  >
                    <DialogContent >
                      <Box p={3} className="dialog-content">
                         <Box className="dialog-header">
                            <h1 className="dialog-title">Edit Experience</h1>
                            <IconButton className="text-3xl" onClick={this.handleCloseExpEdit}><CloseIcon fontSize="inherit" /></IconButton>
                         </Box>
                         <Box my={2}>
                            <img src={avatar} height={"100px"} width={"100px"} alt="" />
                         </Box>
                         <Box width={"100%"}>
                          <Typography variant="body1" style={{fontWeight:500}} component="h6">
                              Company Name
                            </Typography>
                            <OutlinedInput fullWidth className='inputRadius' placeholder="Company Name" />
                         </Box>
                         <Box width={"100%"} mt={2}>
                          <Typography variant="body1" style={{fontWeight:500}} component="h6">
                              Designation
                            </Typography>
                            <OutlinedInput fullWidth className='inputRadius' placeholder="Designation" />
                         </Box>
                         <Box width={"100%"} mt={2}>
                            <Typography variant="body1" style={{fontWeight:500}} component="h6">
                                Industry
                                </Typography>
                               <Grid container spacing={4}>
                                    <Grid item md={6}>
                                      <FormControl  variant="outlined" style={{width: "100%"}}>
                                        <Select
                                          labelId="demo-mutiple-chip-label"
                                          id="demo-mutiple-chip"
                                          label="Industry"
                                          value={this.state.personName}
                                          onChange={this.setPersonName}  
                                          IconComponent={ ExpandMoreIcon }
                                          input={<OutlinedInput className="inputRadius" placeholder="Industry" id="select-multiple-chip" />}
                                        >
                                        {interests.map((name) => (
                                            <MenuItem key={name} value={name}>
                                              {name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item md={6}>
                                      <FormControl  variant="outlined" style={{width: "100%"}}>
                                        <Select
                                          labelId="demo-mutiple-chip-label"
                                          id="demo-mutiple-chip"
                                          label="Industry"
                                          value={this.state.personName}
                                          onChange={this.setPersonName}  
                                          IconComponent={ ExpandMoreIcon }
                                          input={<OutlinedInput className="inputRadius" placeholder="Industry" id="select-multiple-chip" />}
                                        >
                                        {interests.map((name) => (
                                            <MenuItem key={name} value={name}>
                                              {name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                               </Grid>
                            </Box>
                            <Box width={"100%"} my={2}>
                              <Typography variant="body1" style={{fontWeight:500}} component="h6">
                                  Description
                                </Typography>
                                <OutlinedInput fullWidth minRows={3} multiline className='inputRadius' placeholder="Description" />
                            </Box>
                          <Box className="dialog-buttons" mt={2}>
                            <Grid container spacing={2}>
                              <Grid item md={6}>
                                <Button variant="contained" fullWidth color="primary" onClick={this.handleCloseExpEdit} size="large" style={{...styles.button, width:"100%", color:'white'}} disableElevation>
                                  Save
                                </Button>
                              </Grid>
                              <Grid item md={6}>
                                <Button variant="outlined" fullWidth  size="large" onClick={this.handleCloseExpEdit} style={{...styles.button, width:"100%", color:'black'}} disableElevation>
                                    Cancel
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                      </Box>
                    </DialogContent>
                  </Dialog>
                  {/* Edit Dialog Ends */}
                  {/* Delete Dialog Begins */}
                  <Dialog
                    fullWidth
                    maxWidth={'sm'}
                    classes={{paper: 'dialog'}}
                    open={this.state.openExp}
                    onClose={this.handleCloseExp}
                    aria-labelledby="max-width-dialog-title"
                  >
                    <DialogContent >
                      <Box p={3} className="dialog-content">
                        <Box fontSize={72}>
                          <DeleteIcon className="dialog-icon" fontSize={"inherit"} />
                        </Box>
                          <h1 className="dialog-title">Delete Experience</h1>
                          <p className="dialog-description">Are you sure you want to delete the experience?</p>
                          <Box className="dialog-buttons">
                            <Grid container spacing={2}>
                              <Grid item md={6}>
                                <Button variant="contained" fullWidth color="primary" onClick={this.handleCloseExp} size="large" style={{...styles.button, width:"100%", color:'white'}} disableElevation>
                                  Confirm
                                </Button>
                              </Grid>
                              <Grid item md={6}>
                                <Button variant="outlined" fullWidth  size="large" onClick={this.handleCloseExp} style={{...styles.button, width:"100%", color:'black'}} disableElevation>
                                    Cancel
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                      </Box>
                    </DialogContent>
                  </Dialog>
                  {/* Delete Dialog Ends */}
                  {/* Logout Dialog Begins */}
                  <Dialog
                    fullWidth
                    maxWidth={'sm'}
                    classes={{paper: 'dialog'}}
                    open={this.state.openLogout}
                    onClose={this.handleCloseLogout}
                    aria-labelledby="max-width-dialog-title"
                  >
                    <DialogContent >
                      <Box p={3} className="dialog-content">
                        <Box fontSize={72}>
                          <CheckCircleIcon className="dialog-icon" fontSize={"inherit"} />
                        </Box>
                          <h1 className="dialog-title">Log Out</h1>
                          <p className="dialog-description">Are you sure you want to logout from the inspidate?</p>
                          <Box className="dialog-buttons">
                            <Grid container spacing={2}>
                              <Grid item md={6}>
                                <Button variant="contained" fullWidth color="primary" onClick={this.handleCloseLogout} size="large" style={{...styles.button, width:"100%", color:'white'}} disableElevation>
                                  Confirm
                                </Button>
                              </Grid>
                              <Grid item md={6}>
                                <Button variant="outlined" fullWidth  size="large" onClick={this.handleCloseLogout} style={{...styles.button, width:"100%", color:'black'}} disableElevation>
                                    Cancel
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                      </Box>
                    </DialogContent>
                  </Dialog>
                  {/* Logout Dialog Ends */}
                  <Typography variant="body1"  paragraph={true}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi velit nobis eligendi! In ut saepe pariatur voluptatibus corporis commodi, explicabo beatae dolore perspiciatis ducimus recusandae iure, repudiandae quae dolores sequi iusto iste eos, dolorem nemo. Excepturi reprehenderit aperiam maxime molestias.
                    </Typography>
                  <Box>
                    <Button
                        color="primary"
                        style={{fontWeight: 700, fontSize: '16px'}}
                        startIcon={<AddIcon />}
                      >
                      ADD MORE
                      </Button>
                  </Box>
                </Box>
                  <Typography variant="h5" style={{fontWeight: 600}} component="h3">
                    Other Details
                  </Typography>
                <Box mt={3} sx={{width: '100%'}}>
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <Typography variant="body1" style={{fontWeight:500}} component="h6">
                    Country 
                  </Typography>
                  <OutlinedInput fullWidth className='inputRadius' placeholder="Country" />
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body1" style={{fontWeight:500}} component="h6">
                    City
                  </Typography>
                  <OutlinedInput fullWidth className='inputRadius' placeholder="City" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{fontWeight:500}} component="h6">
                  Timezone
                  </Typography>
                  <OutlinedInput fullWidth className='inputRadius' placeholder="Timezone" />
                <Box mt={3}>
                  <Typography variant="body1" style={{fontWeight:500}} component="h6">
                  Languages
                  </Typography>
                  <FormControl  variant="outlined" className={'helo'} style={{width: "100%"}}>
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      variant="outlined"
                      IconComponent={ExpandMoreIcon}
                      value={this.state.personName}
                      onChange={this.setPersonName}  
                      input={<OutlinedInput className="inputRadius" id="select-multiple-chip" />}
                    >
                    {languages.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                    <Box>
                    {(this.state.personName as string[]).map((value) => (
                        <Chip key={value} label={value} variant="outlined"
                        size="small"
                        color="primary"
                        onDelete={()=>{console.log("Delete Chip")}}
                        deleteIcon={<CancelIcon />} style={{marginRight: '10px', marginTop: '10px', padding: '12px', fontSize:'14px', height:'40px', borderRadius: '5px'}} />
                      ))}
                    </Box>
                  </Box>
                  </Grid> 
                </Grid>
                  <Box sx={{margin: '20px 0', display:'flex'}}>
                    <Box sx={{marginRight: '20px',}}>
                      <Button variant="contained" color="primary" size="large" style={{...styles.button, color:'white'}} disableElevation>
                        Save
                      </Button>
                    </Box>
                      <Button variant="outlined" color="default" size="large"  style={{...styles.button}}  disableElevation>
                        I'll do it later
                      </Button>
                  </Box>
                </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        </div>
    );
  }
}
