import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  Paper,
  Checkbox,
  Badge,
  IconButton,
  Divider,
  Select,
  Menu,
  MenuItem,
  Chip,
  RadioGroup,
  Radio,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Avatar
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from "@material-ui/icons/Add";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { avatar, elogo, bag } from "./assets";

// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
} from "./CustomisableUserProfilesController";
// Custom theme begins here
const theme = createTheme({
  palette: {
    primary: {
      main: '#00aeef'
    },
    secondary: {
      main: '#123456'
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      color:'white',
      padding: '10px 20px'
    },
  },
});
// Custom theme ends here

// Custom style begins here
const styles = {
  button: {
    borderRadius: 10,
    padding: '15px 60px',
  },
  inputs: {
    borderRadius: 10,
  },
};
const languages = [
  'Hindi',
  'English',
  'Sanskrit',
  'Tamil',
  'Telugu',
];

const interests = [
  'UI/UX',
  'Copywriting',
  'Frontend Development',
  'Backend Development',
  'Fullstack Development',
];
// Custom style ends here


export default class CompleteProfileBlock extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg">
          <Paper elevation={5} >
            <Box fontWeight="fontWeightBold" p={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start"
            }}>
            <Typography variant="h4" component="h2" style={{fontWeight:'bold'}}>
              Complete Your Profile
            </Typography>
            <Box   sx={{ width: 100, height: 100, margin: '30px 0' }}>
              <img src={avatar} height={"100px"} width={"100px"} alt="helo" />
            </Box>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight:600}} component="h6">
                First Name
              </Typography>
              <TextField fullWidth className='inputRadius' variant="outlined" placeholder="First Name" />
              </Grid>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight:600}} component="h6">
                Last Name
              </Typography>
              <TextField fullWidth className='inputRadius' variant="outlined" placeholder="Last Name" />
              </Grid>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight:600}} component="h6">
                Role
              </Typography>
              <TextField fullWidth className='inputRadius' variant="outlined" placeholder="Role" />
              </Grid>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight:600}} component="h6">
                Professional Experience <span style={{fontWeight:400, fontSize: '12px'}}>(Years)</span>
              </Typography>
              <TextField fullWidth className='inputRadius' variant="outlined" placeholder="Professional Experience" />
              </Grid>
              <Grid item xs={12}>
              <Box mt={3}>
              <Typography variant="body1" style={{fontWeight:600}} component="h6">
              Interests
              </Typography>
              <FormControl  variant="outlined" className={'helo'} style={{width: "100%"}}>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  variant="outlined"
                  value={this.state.interests}
                  onChange={this.setInterests}  
                  input={<Input id="select-multiple-chip" />}
                >
                {interests.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
                <Box>
                {(this.state.interests as string[]).map((value) => (
                    <Chip key={value} label={value} variant="outlined"
                    size="small"
                    color="primary"
                    onDelete={()=>{console.log("Delete Chip")}}
                    deleteIcon={<CancelIcon />} style={{marginRight: '10px', marginTop: '10px', padding: '12px', fontSize:'14px', height:'40px', borderRadius: '5px'}} />
                  ))}
                </Box>
              </Box>
              </Grid> 
              <Grid item xs={12}>
                <Typography variant="body1" style={{fontWeight:600}} component="h6">
                Are you a mentor?
              </Typography>
                 <Box>
                 <RadioGroup row aria-label="mentor" name="mentor" value={this.state.mentor} onChange={this.handleMentorChange}>
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                 </Box>
              </Grid>
            </Grid>
            <hr />
            <Divider />
             <Box>
                <Typography variant="h5" style={{fontWeight: 600, margin:"15px 0"}} component="h3">
                  Experience
                </Typography>
                <Grid  container spacing={4}>
                  <Grid item xs={11}>
                    <Grid container spacing={3}>
                      <Grid item xs={5} style={{margin: "10px 0", display:'flex', flexDirection:'row'}}>
                        <img src={bag} height={"100px"} width={"100px"} alt="Bag" />
                        <Box ml={3}>
                        <Typography variant="h6" component="h4">
                            Senior HR, Delhi
                          </Typography>
                          <Typography variant="body1"  component="h6">
                            Hexa Solutions
                          </Typography>
                          <Typography variant="body1"  component="h6">
                            May 2008 - Present
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                  <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Typography variant="body1"  paragraph={true}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi velit nobis eligendi! In ut saepe pariatur voluptatibus corporis commodi, explicabo beatae dolore perspiciatis ducimus recusandae iure, repudiandae quae dolores sequi iusto iste eos, dolorem nemo. Excepturi reprehenderit aperiam maxime molestias.
                </Typography>
             </Box>
             <Box mb={5}>
             <Grid  container spacing={4}>
                <Grid item xs={11}>
                  <Grid container spacing={3}>
                    <Grid item xs={5} style={{margin: "10px 0", display:'flex', flexDirection:'row'}}>
                      <img src={elogo} height={"100px"} width={"100px"} alt="Bag" />
                      <Box ml={3}>
                      <Typography  variant="h6" component="h4">
                          Trainee HR Manager, Delhi
                        </Typography>
                        <Typography  variant="body1" component="h6">
                         New York Express 
                        </Typography>
                        <Typography  variant="body1" component="h6">
                          May 2006 - 2008
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                <IconButton
                    aria-label="more"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={this.setAnchorEl}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                  >
                    <MenuItem onClick={this.handleClose}>Edit</MenuItem>
                    <MenuItem onClick={this.handleClose}>Delete</MenuItem>
                  </Menu>

                </Grid>
              </Grid>
              <Typography variant="body1"  paragraph={true}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi velit nobis eligendi! In ut saepe pariatur voluptatibus corporis commodi, explicabo beatae dolore perspiciatis ducimus recusandae iure, repudiandae quae dolores sequi iusto iste eos, dolorem nemo. Excepturi reprehenderit aperiam maxime molestias.
                </Typography>
               <Box>
                <Button
                    color="primary"
                    style={{fontWeight: 700, fontSize: '16px'}}
                    startIcon={<AddIcon />}
                  >
                  ADD MORE
                  </Button>
               </Box>
             </Box>
              <Typography variant="h5" style={{fontWeight: 600}} component="h3">
                Other Details
              </Typography>
             <Box mt={3} sx={{width: '100%'}}>
             <Grid container spacing={4}>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight:600}} component="h6">
                Country 
              </Typography>
              <TextField fullWidth className='inputRadius' variant="outlined" placeholder="Country" />
              </Grid>
              <Grid item md={6}>
                <Typography variant="body1" style={{fontWeight:600}} component="h6">
                City
              </Typography>
              <TextField fullWidth className='inputRadius' variant="outlined" placeholder="City" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" style={{fontWeight:600}} component="h6">
              Timezone
              </Typography>
              <TextField fullWidth className='inputRadius' variant="outlined" placeholder="Timezone" />
             <Box mt={3}>
              <Typography variant="body1" style={{fontWeight:600}} component="h6">
              Languages
              </Typography>
              <FormControl  variant="outlined" className={'helo'} style={{width: "100%"}}>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  variant="outlined"
                  value={this.state.personName}
                  onChange={this.setPersonName}  
                  input={<Input id="select-multiple-chip" />}
                >
                {languages.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
                <Box>
                {(this.state.personName as string[]).map((value) => (
                    <Chip key={value} label={value} variant="outlined"
                    size="small"
                    color="primary"
                    onDelete={()=>{console.log("Delete Chip")}}
                    deleteIcon={<CancelIcon />} style={{marginRight: '10px', marginTop: '10px', padding: '12px', fontSize:'14px', height:'40px', borderRadius: '5px'}} />
                  ))}
                </Box>
              </Box>
              </Grid> 
            </Grid>
              <Box sx={{margin: '20px 0', display:'flex'}}>
                <Box sx={{marginRight: '20px',}}>
                  <Button variant="contained" color="primary" size="large" style={{...styles.button, color:'white'}} disableElevation>
                    Save
                  </Button>
                </Box>
                  <Button variant="outlined" color="default" size="large"  style={{...styles.button}}  disableElevation>
                    I'll do it later
                  </Button>
              </Box>
             </Box>
            </Box>
          </Paper>
        </Container>
      </ThemeProvider>
    );
  }
}
