export const inspidate = require("../assets/inspidate.png");
export const icon1 = require("../assets/1.png");
export const icon2 = require("../assets/2.png");
export const icon3 = require("../assets/3.png");
export const icon4 = require("../assets/4.png");
export const icon5 = require("../assets/5.png");
export const icon6 = require("../assets/6.png");
export const video = require("../assets/video.mp4");
export const listArrow = require("../assets/listArrow.png");
export const cta = require("../assets/cta.jpg");
export const hero = require("../assets/hero.jpg");
export const videoPoster = require("../assets/videoPoster.jpg");
export const bag = require("../assets/bag.png");
export const mentor1 = require("../assets/mentor1.png");
export const mentor2 = require("../assets/mentor2.png");
export const mentor3 = require("../assets/mentor3.png");
export const calendar = require("../assets/calendar.png");
export const airbnb = require("../assets/airbnb.png");
export const bonnie = require("../assets/bonnie.png");
export const growthMarketing = require("../assets/growth_marketing.png");
export const jonathan = require("../assets/jonathan.png");
export const newYorkExpress = require("../assets/newyork_express.png");
export const productAnalytics = require("../assets/product_analytics.png");
export const v1 = require("../assets/v1.png");
export const v2 = require("../assets/v2.png");
export const v3 = require("../assets/v3.png");
export const v4 = require("../assets/v4.png");
export const facebook = require("../assets/facebook.png");
export const instagram = require("../assets/instagram.png");
export const linkedin = require("../assets/linkedin.png");
export const twitter = require("../assets/twitter.png");


